.icon {
	display: inline-block;
	min-width: 20px;
	height: 20px;
	overflow: hidden;
	vertical-align: top;
	background-image: url('../images/bg_sprite.png');
	background-repeat: no-repeat;
	background-size: 400px auto;
	}
.icon-group {
	margin-right: 10px;
	}
.add-new-card-green {
	display: inline-block;
	margin-top: 34px;
	width: 38px;
	height: 36px;
	background-position: -210px -400px;
	}
.add-new-card-grey {
	display: inline-block;
	margin-top: 34px;
	width: 38px;
	height: 36px;
	background-position: 0 -1600px;
	}
.add-new-card-green-large {
	display: inline-block;
	margin-top: 34px;
	width: 77px;
	height: 72px;
	background-position: -80px -400px;
	}
.add-new-card-white {
	display: inline-block;
	margin-left: 5px;
	margin-right: 8px;
	width: 25px;
	height: 24px;
	vertical-align: middle;
	background-position: -210px -320px;
	}
.add-plus-white {
	background-position: -114px -44px;
	background-color: #fff;
	}
.add-plus-grey-transparent-bg {
	background-position: -114px -64px;
	}	
.add-plus-grey {
	background-position: -114px -44px;
	background-color: #ebe9e7;
	}
.add-sections {
	height: 21px;
  	width: 21px;
  	min-width: 21px;
	background-position: -90px -500px;
	}
.add-sections-large {
	height: 29px;
  	width: 29px;
  	min-width: 29px;
	background-position: -120px -500px;
	}
.ae {
	height: 20px;
	background-position: -360px -1550px;
	}
/*AE reported with green checkmark no longer in use, so both classes have sam bg sprite */
.ae-reported,
.ae-reported-blue {
	height: 20px;
	background-position: -370px -1600px;
	}		
.alarm-clock-blue-large {
	height: 28px;
  	width: 28px;
	background-position: -160px -890px;
	}
.alarm-clock-blue-small {
	height: 16px;
  	width: 16px;
  	min-width: 16px;
	background-position: -160px -930px;
	}
.alarm-clock-grey-large {
	height: 28px;
  	width: 28px;
	background-position: -120px -890px;
	}
.alarm-clock-grey-small {
	height: 16px;
  	width: 16px;
  	min-width: 16px;
	background-position: -120px -930px;
	}
.alarm-clock-orange-large {
	height: 28px;
  	width: 28px;
	background-position: -200px -890px;
	}
.alarm-clock-orange-small {
	height: 16px;
  	width: 16px;
  	min-width: 16px;
	background-position: -200px -930px;
	}
.api-medium {
	width: 20px;
	height: 20px;
	background-position: -220px -1490px;
	}	
.api-blue-xs {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -90px -1920px;
	}
.arrow-back-large {
	width: 19px;
	min-width: 19px;
	height: 14px;
	background-position: -320px -470px;
	}
.arrow-black-left-small {
	width: 14px;
	min-width: 14px;
	height: 11px;
	background-position: -90px -1840px;
	}
.arrow-black-left-large {
	width: 28px;
	height: 28px;
	background-position: -90px -1775px;
	}
.arrow-black-right {
	width: 5px;
	min-width: 5px;
	height: 6px;
	background-position: -140px -480px;
	}
.arrow-black-right-small {
	width: 4px;
	min-width: 4px;
	height: 5px;
	background-position: -150px -480px;
	}
.arrow-blue-apply {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -120px -1880px;
	}
.arrow-blue-up {
	width: 8px;
	min-width: 8px;
	height: 10px;
	background-position: -240px -830px;
	}
.arrow-blue-up-box {
	width: 12px;
	min-width: 12px;
	height: 12px;
	background-position: -190px -1600px;
	}	
.arrow-blue-right {
	width: 10px;
	min-width: 10px;
	height: 8px;
	background-position: -240px -845px;
	}
.arrow-blue-round-right {
	background-position: -30px -890px;
	}
.arrow-round-blue-down {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -90px -1280px;
	}
.arrow-round-blue-down-medium {
	width: 22px;
	height: 22px;
	margin-top: -1px;
	background-position: -220px -1215px;
	}
.arrow-green-down {
	width: 5px;
	min-width: 5px;
	height: 4px;
	background-position: -260px -1240px;
	}
.arrow-round-gray-down {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -110px -1280px;
	}
.arrow-white-down {
	width: 8px;
	min-width: 8px;
	height: 4px;
	background-position: -160px -790px;
	}
.arrow-white-up {
	width: 8px;
	min-width: 8px;
	height: 4px;
	background-position: -160px -780px;
	}	
.arrow-grey-apply {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -60px -1860px;
	}
.arrow-grey-left {
	width: 5px;
	min-width: 5px;
	height: 9px;
	vertical-align: top !important;
	background-position: -380px -530px;
	}
.arrow-grey-left-white-bg {
	height: 20px;
	background-position: -340px -1580px;
	}
.arrow-grey-round-left {
	background-position: -60px -890px;
	}
.arrow-red-up {
	width: 5px;
	min-width: 5px;
	height: 4px;
	background-position: -260px -1250px;
	}
.arrow-red-change {
	width: 15px;
	min-width: 15px;
	height: 18px;
	background-position: -270px -1240px;
	}	
.attention {
	padding: 0 0 10px;
	color: #fff;
	text-align: center;
	background-position: 0 -50px;
	}
.attention-orange-round-large {
	width: 44px;
	height: 44px;
	background-position: -200px -1110px;
	}	
.attention-orange-round-medium {
	width: 22px;
	height: 22px;
	background-position: -200px -1160px;
	}
.attention-orange-round-small {
	width: 20px;
	height: 20px;
	background-position: -200px -1190px;
	}
.attention-orange-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -200px -1215px;
	}
.badge-verified {
	width: 14px;
	min-width: 14px;
	height: 18px;
	background-position: -310px -1320px;	
	}
.badge-unverified {
	width: 14px;
	min-width: 14px;
	height: 18px;
	background-position: -310px -1340px;	
	}
.book-blue-xs {
	width: 15px;
	min-width: 15px;
	height: 17px;
	background-position: -40px -1920px;
	}
.booklet-blue-small {
	width: 15px;
	min-width: 15px;
	height: 17px;
	background-position: -235px -1580px;
	}
.booklet-blue-medium {
	width: 23px;
	height: 26px;
	background-position: -235px -1550px;
	}
.booklet-blue-large {
	width: 40px;
	height: 46px;
	background-position: -155px -1550px;
	}
.booklet-gray-small {
	width: 15px;
	min-width: 15px;
	height: 17px;
	background-position: -285px -1580px;
	}
.booklet-gray-medium {
	width: 23px;
	height: 26px;
	background-position: -285px -1550px;
	}
.booklet-red-info-large {
	width: 40px;
	height: 46px;
	background-position: -195px -1550px;
	}
.booklet-orange-small {
	width: 15px;
	min-width: 15px;
	height: 17px;
	background-position: -260px -1580px;
	}
.booklet-orange-medium {
	width: 23px;
	height: 26px;
	background-position: -260px -1550px;
	}
.car-blue-right-large {
	width: 30px;
	height: 19px;
	background-position: -295px -1810px;
	}
.car-blue-right-xs {
	width: 22px;
	height: 14px;
	background-position: -15px -1920px;
	}
.car-blue-faded-right-large {
	width: 33px;
	height: 21px;
	background-position: -355px -1770px;
	}
.car-green-left-small {
	width: 22px;
	height: 16px;
	background-position: -170px -1760px;
	}
.car-grey-left-small {
	width: 22px;
	height: 14px;
	background-position: -295px -1850px;
	}
.car-grey-right-small {
	width: 22px;
	height: 14px;
	background-position: -195px -1760px;
	}
.car-grey-right-large {
	width: 30px;
	height: 19px;
	background-position: -295px -1830px;
	}
.car-grey-left-cancelled-small {
	width: 22px;
	height: 14px;
	background-position: -295px -1865px;
	}
.car-grey-right-cancelled-small {
	width: 22px;
	height: 14px;
	background-position: -220px -1760px;
	}
.certificate-large {
	width: 49px;
	height: 48px;
	margin-top: 4px;
	margin-left: 5px;
	background-position: 0 -950px;
	}
.certificate-medium {
	width: 36px;
	height: 36px;
	background-position: 0 -1010px;
	}
.certificate-small {
	width: 24px;
	height: 24px;
	background-position: 0 -1055px;
	}
.not-certified-small {
	width: 24px;
	height: 23px;
	background-position: -150px -1710px;
	}
.certificate-unpublished-large {
	width: 57px;
	height: 57px;
	background-position: -60px -950px;
	}		
.certificate-unpublished-medium {
	width: 43px;
	height: 43px;
	margin-top: -2px;
	margin-left: -2px;
	background-position: -60px -1010px;
	}
.certificate-unpublished-small {
	width: 28px;
	height: 28px;
	background-position: -60px -1055px;
	}
.chartcolumn-large {
	width: 46px;
	height: 34px;
	background-position: -100px -1320px;
	}
.active .chartcolumn-large,
a:hover .chartcolumn-large {
	background-position: -100px -1360px;
	}
.chartline-large {
	width: 46px;
	height: 34px;
	background-position: -150px -1320px;
	}
.active .chartline-large,
a:hover .chartline-large {
	background-position: -150px -1360px;
	}
.chartpie-large {
	width: 34px;
	height: 37px;
	background-position: -50px -1320px;
	}
.active .chartpie-large,
a:hover .chartpie-large {
	background-position: -50px -1360px;
	}	
.check-green {
	width: 14px;
	min-width: 14px;
	height: 11px;
	background-position: -295px -1615px;
	}
.check-grey {
	width: 14px;
	min-width: 14px;
	height: 11px;
	background-position: -295px -1600px;
	}
.check-white-large {
	width: 22px;
	height: 17px;
	background-position: -270px -1600px;
	}
.check-white-large {
	width: 22px;
	height: 17px;
	background-position: -270px -1600px;
	}
.circle-yellow-medium {
	width: 17px;
	min-width: 17px;
	height: 17px;
	background-position: -120px -1030px;
	}		
.copy-blue {
	width: 16px;
	min-width: 16px;
	height: 16px;
	margin-right: 8px;
	background-position: -320px -830px;
	}
.data-inclusive-inbetween {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: 0 -1480px;
	}
.data-inclusive-inbetween-blue {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: 0 -1500px;
	}	
.data-less-than {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -80px -1480px;
	}	
.data-less-than-blue {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -80px -1500px;
	}
.data-less-than-or-equal-to {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -100px -1480px;
	}
.data-less-than-or-equal-to-blue {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -100px -1500px;
	}
.data-greater-than {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -20px -1480px;
	}
.data-greater-than-blue {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -20px -1500px;
	}
.data-greater-than-or-equal-to {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -40px -1480px;
	}
.data-greater-than-or-equal-to-blue {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -40px -1500px;
	}
.data-equal {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -60px -1480px;
	}
.data-equal-blue {
	width: 18px;
	min-width: 18px;
	height: 16px;
	background-position: -60px -1500px;
	}
.data-large {
	width: 48px;
	height: 33px;
	background-position: 0 -1320px;
	}
.dice-blue-xs {
	width: 15px;
	min-width: 15px;
	height: 15px;
	background-position: -55px -1920px;
	}	
.document-blue {
	width: 12px;
	min-width: 12px;
	height: 16px;
	margin-right: 8px;
	background-position: -320px -855px;
	}
.download-blue {
	width: 24px;
	height: 24px;
	background-position: -320px -1010px;
	}
.download-box-white {
	width: 24px;
	height: 24px;
	background-position: 0 -920px;
	}	
.emdash-grey {
	width: 12px;
	min-width: 12px;
	height: 2px;
	background-position: -60px -1450px;
	}
.active .data-large,
a:hover .data-large  {
	background-position: 0 -1360px;
	}
.etmf-blue-xs {
	width: 17px;
	min-width: 17px;
	height: 17px;
	background-position: -110px -1920px;
	}
.expired-red-small {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -60px -1840px;
	}	
.expired-red-large {
	width: 28px;
	height: 28px;
	background-position: -60px -1775px;
	}			
.level-rookie {
	width: 20px;
	height: 16px;
	vertical-align: middle;
	background-position: -150px 0px;
	}
.level-rookie-large {
	width: 26px;
	height: 21px;
	vertical-align: middle;
	background-position: 0px -855px;
	}
.level-normal {
	width: 19px;
	height: 16px;
	vertical-align: middle;
	background-position: -180px 0px;
	}
.level-normal-large {
	width: 28px;
	height: 22px;
	vertical-align: middle;
	background-position: -30px -855px;
	}
.level-advanced {
	width: 20px;
	height: 19px;
	vertical-align: middle;
	background-position: -210px 0px;
	}
.level-advanced-large {
	width: 30px;
	height: 25px;
	vertical-align: middle;
	background-position: -60px -855px;
	}
.level-master {
	width: 21px;
	height: 17px;
	vertical-align: middle;
	background-position: -240px 0px;
	}
.level-master-large {
	width: 31px;
	height: 24px;
	vertical-align: middle;
	background-position: -90px -855px;
	}
.role-icon {
	display: block;
	width: 70px;
	height: 70px;
	background-image: url('../images/roles.png');
	background-repeat: no-repeat;
	background-size: 330px auto;
	}
.av-1 {
	background-position: -8px -16px;
	}
.av-2 {
	background-position: -88px -16px;
	}
.av-3 {
	background-position: -167px -16px;
	}
.av-4 {
	background-position: -246px -16px;
	}
.av-5 {
	background-position: -8px -101px;
	}
.av-6 {
	background-position: -88px -101px;
	}
.av-7 {
	background-position: -166px -100px;
	}
.av-8 {
	background-position: -246px -100px;
	}
.bell {
	min-height: 20px;
	background-position: -60px -360px;
	}
.bell-large {
	width: 26px;
	height: 26px;
	background-position: -90px -360px;
	}
.bell-xl {
	width: 35px;
	height: 36px;
	background-position: -30px -380px;
	}
.bubble-grey {
	width: 25px;
	height: 22px;
	background-position: -210px -600px;
	}
.calendar {
	min-height: 20px;
	background-position: 0 -210px;
	}
.calendar-blue {
	min-height: 20px;
	background-position: -60px -230px;
	}
.calendar-blue-small {
	width: 13px;
	min-width: 13px;
	height: 13px;
	background-position: 0 -230px;
	}
.caret-blue-left-small {
	width: 4px;
	min-width: 4px;
	height: 9px;
	background-position: -290px -875px;
	}	
.caret-blue-down {
	width: 8px;
	min-width: 8px;
	height: 5px;
	background-position: -180px -1410px;
	}
.caret-blue-down-s {
	width: 7px;
	min-width: 7px;
	height: 4px;
	background-position: -190px -1410px;
	}		
.caution-orange-small {
	width: 14px;
	min-width: 14px;
	height: 12px;
	background-position: -100px -480px;
	}
.changed-value {
	width: 12px;
	min-width: 12px;
	height: 12px;
	background-position: -120px -480px;
	}
.chat-bubbles-grey {
	width: 36px;
	height: 26px;
	background-position: -200px -990px;
	}
.chat-bubbles-dark-grey {
	width: 27px;
	height: 20px;
	background-position: -240px -990px;
	}	
.clip {
	min-height: 34px;
	margin-top: -23px;
	background-position: 0 -400px;
	}
.clip-black-small {
	width: 14px;
	min-width: 14px;
	height: 13px;
	vertical-align: middle;
	background-position: -150px -1240px;
	}
.clip-blue {
	width: 15px;
	height: 13px;
	background-position: -60px -190px;
	}
.clock {
	min-height: 20px;
	background-position: 0 -450px;
	}
.clock-black {
	width: 15px;
	min-width: 15px;
	height: 15px;
	background-position: -180px -1030px;
	}
.clock-blue {
	background-position: 0 -360px;
	}
.clock-blue-small {
	width: 15px;
	min-width: 15px;
	background-position: 0 -380px;
	}
.clock-blue-medium {
	width: 22px;
	height: 22px;
	background-position: -200px -1240px;
	}
.clock-grey-large {
	width: 22px;
	height: 22px;
	background-position: -360px -1310px;
	}
.close-grey-round {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -250px -830px; 
	}
.close-black-large {
	width: 22px;
	height: 22px;
	background-position: -220px -950px; 
	}
.close-blue-round-large {
	width: 26px;
	height: 26px;
	background-position: -355px -1740px; 
	}
.cog-blue-small {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: 0 -1880px;
	}
.cog-blue-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -20px -1880px;
	}	
.cog-favourite-grey-medium {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -40px -1860px;
	}	
.cog-favourite-blue-medium {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -40px -1880px;
	}	
.cog-grey-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -20px -1860px;
	}	
.cog-grey-light-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: 0 -1860px;
	}
.cog-grey-light-xxl {
	width: 48px;
	height: 48px;
	background-position: -140px -1860px;
	}
.cog-error-red-medium {
	width: 21px;
	height: 14px;
	background-position: 0 -1900px;
	}	
.cra {
	width: 16px;
	min-width: 16px;
	background-position: 0px -145px;
	}
.cra-off {
	width: 16px;
	min-width: 16px;
	background-position: 0px -165px;
	}
.delete {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-left: 15px;
	float: none !important;
	vertical-align: middle;
	background-position: -360px -280px;
	}
.delete-grey-small {
	width: 12px;
	height: 12px;
	background-position: -125px -150px;
	}
.delete-grey-round {
	width: 16px;
	min-width: 16px;
	height: 16px;
	vertical-align: middle;
	background-position: -250px -1490px;
	}
a:hover .delete-grey-round {
	background-position: -250px -1510px;
	}
.icon.deleted {
	font-size: 12px !important;
	line-height: 20px;
	padding: 1px 7px 0 7px !important;
	color: #FF4838;
	text-align: center;
	background-color: #fff;
	background-image: none !important;
	font-weight: 700;
	}
.deleted.bg-red {
	color: #fff;
	}
.demo-badge {
	width: 76px;
	height: 28px !important;
	background-position: 0 -550px;
	}
.designer-grey-large {
	width: 31px;
	height: 28px;
	background-position: -280px -890px;
	}	
.designer-yellow-large {
	width: 31px;
	height: 28px;
	background-position: -240px -890px;
	}
.device-green-small {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -150px -1840px;
	}		
.device-blue-small {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -180px -1840px;
	}
.device-grey-large {
	width: 20px;
	height: 32px;
	background-position: -210px -1775px;
	}
.device-brown-small {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -210px -1840px;
	}	
.device-burgundy-small {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -240px -1840px;
	}
.device-black-small {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -270px -1840px;
	}
.dice-grey-medium {
	width: 20px;
	height: 20px;
	background-position: -220px -660px;
	}
.dice-grey-small {
	width: 12px;
	height: 12px;
	background-position: -220px -680px;
	}
.dice-red-medium {
	width: 20px;
	height: 20px;
	background-position: -240px -660px;
	}
.dice-red-small {
	width: 12px;
	height: 12px;
	background-position: -240px -680px;
	}
.dictionary-blue {
	width: 22px;
	height: 24px;
	background-position: -340px -1455px;
	}
.dictionary-grey {
	width: 22px;
	height: 24px;
	background-position: -340px -1430px;
	}
.dm {
	width: 12px;
	min-width: 12px;
	background-position: -20px -145px;
	}
.dm-off {
	width: 12px;
	min-width: 12px;
	background-position: -20px -165px;
	}
.dot-blue {
	width: 8px;
	min-width: 8px;
	height: 8px;
	background-position: -360px -40px;
	}
.dot-grey {
	width: 8px;
	min-width: 8px;
	height: 8px;
	background-position: -360px -200px;	
	}
.dot-orange {
	width: 8px;
	min-width: 8px;
	height: 8px;
	background-position: -360px -430px;	
	}	
.dot-red {
	width: 8px;
	min-width: 8px;
	height: 8px;
	background-position: -360px -120px;	
	}
.dot-red-smaller {
	width: 6px;
	min-width: 6px;
	height: 6px;
	background-position: -390px -1550px;	
	}	
.download {
	width: 18px;
	min-width: 18px;
	height: 18px;
	background-position: -300px -530px;
	}
.duplicate-grey-large {
	width: 47px;
	height: 53px;
	background-position: -120px -1050px;	
	}
.duplicate-orange-small {
	width: 15px;
	min-width: 15px;
	height: 18px;
	margin-top: 1px;
	background-position: -250px -1190px;	
	}
.duplicate-orange-xs {
	width: 10px;
	min-width: 10px;
	height: 11px;
	background-position: -250px -1215px;	
	}
.error {
	margin: 0 !important;
	color: #fff;
	text-align: center;
	background-position: 0 -50px;
	}
.event {
	font-size: 12px !important;
	line-height: 20px;
	padding: 1px 3px 0 3px;
	color: #fff;
	text-align: center;
	background-color: #3fa9f5;
	background-image: none;
	}
.edit-lock-small {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -60px -480px;
	}
.edit-lock-large {
	display: block;
	position: absolute;
	width: 60px;
	height: 60px;
	z-index: 20;
	top: 55px;
	left: 45%;
	background-position: 0 -650px; 	
	}
.edit-lock-medium {
	width: 24px;
	height: 24px;
	background-position: 0px -710px; 
	}
.elearning-white {
	width: 28px;
	height: 28px;
	background-position: -180px -600px;
	}
.elearning-grey {
	width: 28px;
	height: 30px;
	background-position: -120px -990px;
	}
.elearning-large {
	width: 46px;
	height: 50px;
	background-position: -270px -750px;
	}
.email-large {
	width: 32px;
	height: 22px;
	vertical-align: middle;
	background-position: -110px -1240px;
	}
.exclamation-black-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -250px -930px;
	}
.exclamation-bubble-grey-large {
	width: 32px;
	height: 32px;
	background-position: -315px -1110px;
	}	
.exclamation-red {
	width: 11px;
	min-width: 11px;
	height: 14px;
	margin-right: 3px;
	background-position: -376px -500px;
	}	
.exclamation-red-xs {
	width: 5px;
	min-width: 5px;
	height: 12px;
	background-position: -390px -500px;
	}	
.exclamation-grey-xs {
	width: 5px;
	min-width: 5px;
	height: 12px;
	background-position: -380px -515px;
	}
.exclamation-red-xxs {
	vertical-align: middle;
	margin-top: -2px;
	width: 3px;
	min-width: 3px;
	height: 11px;
	background-position: -390px -515px;
	}
.exclamation-red-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -240px -930px;
	}
.exclamation-red-round {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-right: 4px;
	background-position: -165px -1240px;
	}
.exclamation-red-large {
	width: 10px;
	min-width: 10px;
	height: 28px;
	background-position: -230px -250px;
	}	
.exclamation-violet-white-bg {
	height: 20px;
	background-position: -310px -1580px;
	}
.exclude-large {
	display: block;
	position: absolute;
	width: 60px;
	height: 60px;
	z-index: 20;
	top: 55px;
	left: 45%;
	background-position: 0 -280px; 
	}
.exclude-medium {
	width: 24px;
	height: 24px;
	background-position: 0 -255px; 
	}
.eye-blue-round {
	width: 28px;
	height: 28px;
	background-position: -140px -750px;
	}
.eye-darkgrey {
	width: 23px;
	height: 13px;
	background-position: -210px -470px; 
	}
.eye-grey {
	width: 22px;
	height: 18px;
	background-position: -210px -500px; 
	}
.eye-green {
	margin-top: 2px;
	width: 22px;
	height: 13px;
	background-position: -210px -530px; 
	}
.eye-white {
	width: 30px;
	height: 17px;
	background-position: -180px -360px;
	}
.eye-white-small {
	width: 18px;
	min-width: 18px;
	height: 10px;
	background-position: -140px -780px;
	}
.export {
	width: 23px;
	height: 24px;
	background-position: -210px -360px;
	}
.export-templates {
	width: 26px;
	height: 34px;
	background-position: -30px -600px;
	}
.female-small {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -150px -320px; 
	}
.female-black-small {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -230px -1400px; 
	}
.female-demo-small {
	width: 32px;
	height: 22px;
	background-position: -140px -710px; 
	}
.female-black-demo-small {
	width: 32px;
	height: 22px;
	background-position: -270px -1400px; 
	}
.file-custom-url {
	width: 24px;
	height: 26px;
	background-position: -260px -1630px; 
	}
.file-custom-url-large {
	width: 32px;
	height: 35px;
	background-position: -260px -1660px; 
	}
.file-generic-blank-grey {
	width: 24px;
	height: 30px;
	background-position: -190px -1630px;
	}
.file-generic-blank-grey-large {
	width: 32px;
	height: 40px;
	background-position: -190px -1660px;
	}
.file-generic-grey {
	width: 24px;
	height: 32px;
	background-position: -240px -855px; 
	}
.file-elearning {
	width: 26px;
	height: 30px;
	background-position: -225px -1630px; 
	}
.file-elearning-large {
	width: 32px;
	height: 34px;
	background-position: -225px -1660px;
	}
.file-pdf {
	width: 26px;
	height: 32px;
	background-position: -270px -650px; 
	}
.file-pdf-bw {
	width: 26px;
	height: 32px;
	background-position: -270px -1700px; 
	}
.file-pdf-red {
	width: 24px;
	height: 30px;
	background-position: -200px -1400px; 
	}
.file-pdf-red-large {
	width: 32px;
	height: 40px;
	background-position: -190px -1700px; 
	}
.file-pdf-red-small {
	width: 12px;
	min-width: 12px;
	height: 15px;
	vertical-align: middle;
	margin-top: -2px;
	background-position: -315px -1430px; 
	}
.file-pdf-red-medium-2 {
	width: 16px;
	min-width: 16px;
	height: 20px;
	background-position: -315px -1480px; 
	}
.file-pdf-red-medium {
	width: 20px;
	height: 25px;
	background-position: -315px -1450px; 
	}
.file-doc-word-blue {
	width: 24px;
	height: 30px;
	background-position: -295px -1630px; 
	}
.file-doc-word-blue-large {
	width: 32px;
	height: 40px;
	background-position: -295px -1660px;
	}
.file-ppt-powerpoint-red {
	width: 24px;
	height: 30px;
	background-position: -330px -1630px; 
	}
.file-ppt-powerpoint-red-large {
	width: 32px;
	height: 40px;
	background-position: -330px -1660px; 
	}
.file-xls-excel-green {
	width: 24px;
	height: 30px;
	background-position: -365px -1630px; 
	}
.file-xls-excel-green-large {
	width: 32px;
	height: 40px;
	background-position: -365px -1660px; 
	}
.file-xls-grey {
	width: 20px;
	height: 25px;
	background-position: -340px -1400px;	
	}
.file-upload-default {
	width: 50px;
	height: 56px;
	background-position: -150px -1260px;	
	}
.file-upload-extension {
	width: 50px;
	height: 56px;
	background-position: -290px -1050px;	
	}
.folder-grey-small {
	width: 15px;
	min-width: 0;
	height: 12px;
	margin-top: -2px;
	background-position: -370px -1480px;	
	}
.folder-locked-large {
	width: 25px;
	height: 23px;
	background-position: -60px -525px; 
	}
.forbidden {
	width: 12px;
	min-width: 12px;
	height: 12px;
	vertical-align: middle;
	background-position: -150px -500px;
	}
.forbidden-grey-small {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -100px -830px;
	}
.forbidden-grey-large {
	width: 30px;
	height: 30px;
	background-position: -165px -430px;
	}
.forbidden-round-large {
	width: 44px;
	height: 44px;
	background-position: -150px -1110px;
	}
.forbidden-round-medium {
	width: 22px;
	height: 22px;
	background-position: -150px -1160px;
	}
.forbidden-round-small {
	width: 20px;
	height: 20px;
	background-position: -150px -1190px;
	}
.forbidden-round {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-right: 4px;
	background-position: -165px -1215px;
	}
.forbidden-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -150px -1215px;
	}
.forbidden-round-grey {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-right: 4px;
	background-position: -180px -1215px;
	}
.forbidden-round-grey-light {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -180px -1190px;
	}
.ghs {
	width: 20px;
	height: 20px;
	background-position: -315px -1550px;
	}
.history {
	width: 12px;
	min-width: 12px;
	height: 13px;
	background-position: -40px -480px;
	}
.info-blue-small {
	width: 8px;
	min-width: 8px;
	height: 14px;
	background-position: -290px -470px;
	}
.info-blue-small-serif {
	width: 6px;
	min-width: 6px;
	height: 12px;
	background-position: -260px -1280px;
	}
.info-blue-large {
	width: 13px;
	min-width: 13px;
	height: 24px;
	background-position: -300px -470px;
	}
.info-grey {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -180px -500px;
	}
.info-grey-round-xl {
	width: 100px;
	height: 100px;
	background-position: 0px -750px;
	}
.info-grey-round-large {
	width: 50px;
	height: 50px;
	background-position: -90px -230px;
	}
.info-grey-light-round-large {
	width: 50px;
	height: 50px;
	background-position: -300px -1700px;
	}
.invalid-red-large {
	width: 28px;
	height: 28px;
	background-position: -325px -1900px;
	}
.invalid-red-small {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -325px -1880px;
	}
.key-grey-medium {
	width: 16px;
	min-width: 16px;
	height: 24px;
	background-position: -130px -1515px;
	}
.line-through-bullet-small {
	width: 10px;
	min-width: 10px;
	height: 7px;
	background-position: -165px -1675px;
	}
.line-through-bullet {
	width: 20px;
	height: 14px;
	background-position: -165px -1660px;
	}
.link-black {
	width: 8px;
	min-width: 8px;
	height: 18px;
	background-position: -120px -1480px;
	}	
.list-item-green {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -360px -830px;
	}
.list-item-green-large {
	width: 20px;
	height: 22px;
	background-position: -360px -1070px;
	}
.list-item-grey {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -360px -750px;
	}
.list-item-grey-large {
	width: 20px;
	height: 22px;
	background-position: -360px -990px;
	}
.list-item-grey-light {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -120px -1860px;
	}
.list-item-red {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -360px -910px;
	}
.list-item-red-large {
	width: 20px;
	height: 22px;
	background-position: -360px -1150px;
	}
.lock {
	width: 12px;
	min-width: 12px;
	background-position: -60px -147px;
	}
.lock-badge {
	width: 40px;
	height: 28px !important;
	background-position: -290px -1280px;
	}
.lock-off {
	width: 12px;
	min-width: 12px;
	background-position: -60px -167px;
	}
.lock-large {
	display: block;
	position: absolute;
	width: 60px;
	height: 60px;
	z-index: 20;
	top: 45px;
	left: 45%;
	background-position: -60px -280px; 
	}
.lock-medium {
	width: 24px;
	height: 24px;
	background-position: -60px -255px; 
	}
.lock-black-small {
	width: 14px;
	min-width: 14px;
	height: 16px;
	background-position: -260px -1260px; 
	}
.lock-black-medium {
	width: 14px;
	min-width: 14px;
	height: 18px;
	background-position: -290px -1240px; 
	}
.lock-blue-check-mark-medium {
	width: 18px;
	min-width: 18px;
	height: 20px;
	background-position: -340px -1600px;
	}
.lock-green-check-mark-medium {
	width: 18px;
	min-width: 18px;
	height: 20px;
	background-position: -340px -1550px;
	}
.lock-grey-medium {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -320px -930px;
	}
.lock-grey-medium-2 {
	width: 19px;
	min-width: 19px;
	height: 24px;
	background-position: 0 -1520px;
	}
.lock-exed {
	width: 28px;
	height: 24px;
	background-position: -120px -830px;
	}
.locked-dark-grey {
	width: 12px;
	min-width: 12px;
	height: 15px;
	background-position: -180px -1240px;
	}
.locked-dark-grey-large {
	width: 18px;
	min-width: 18px;
	height: 22px;
	background-position: -290px -930px;
	}
.lock-black-large {
	width: 36px;
	height: 46px;
	background-position: -250px -1110px;
	}
.lock-black-medium {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -250px -1160px;
	}
.lock-black-small {
	width: 14px;
	min-width: 14px;
	height: 18px;
	background-position: -290px -1240px;
	}
.lock-black-xs {
	width: 7px;
	min-width: 9px;
	height: 9px;
	background-position: -290px -1260px;
	}
.lock-grey-large {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -320px -930px;
	}
.unlock-grey-large {
	width: 16px;
	min-width: 16px;
	height: 21px;
	background-position: -290px -1360px;
	}
.lock-red-large {
	width: 19px;
	min-width: 19px;
	height: 24px;
	background-position: -250px -1600px;
	}	
.lock-white-medium {
	width: 16px;
	min-width: 16px;
	height: 20px;
	margin-top: -4px;
	background-position: -270px -1160px;
	}	
.mail-grey {
	width: 24px;
	height: 16px;
	background-position: -240px -600px;
	}
.male-small {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -180px -320px; 
	}
.male-black-small {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -250px -1400px; 
	}
.male-demo-small {
	width: 32px;
	height: 22px;
	background-position: -180px -710px; 
	}
.male-black-demo-small {
	width: 32px;
	height: 22px;
	background-position: -302px -1400px; 
	}
.message {
	margin-left: 20px;
	background-position: -270px -423px;
	}
.message-grey {
	width: 30px;
	height: 20px;
	background-position: -160px -990px;
	}
.message-xl {
	width: 42px;
	height: 28px;
	background-position: -30px -420px;
	}
.minus-red {
	width: 14px;
	min-width: 14px;
	height: 4px;
	background-position: -260px -1300px;
	}	
.minus-red-round {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -240px -530px;
	}	
.minus-round-red-strong {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -90px -1300px;
	}
.minus-grey-box-white {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -85px -1520px;
	}
.minus-white {
	width: 12px;
	min-width: 12px;
	height: 12px;
	background-position: -120px -210px;
	}
.minimize {
	width: 18px;
	min-width: 18px;
	height: 18px;
	background-position: -270px -530px;
	}
.missing-metrics-large {
	width: 24px;
	height: 24px;
	background-position: -260px -1320px;
	}
.active .missing-metrics-large,
a:hover .missing-metrics-large {
	background-position: -260px -1360px;
	}
.no-entry-round-large {
	width: 44px;
	height: 44px;
	background-position: -100px -1110px;
	}
.no-entry-round-medium {
	width: 22px;
	height: 22px;
	background-position: -100px -1160px;
	}
.no-entry-round-small {
	width: 20px;
	height: 20px;
	background-position: -100px -1190px;
	}
.no-entry-round-small-2 {
	width: 20px;
	height: 20px;
	background-position: -50px -1520px;
	}
.no-entry-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -100px -1215px;
	}
.note-pen {
	width: 22px;
	margin-top: -2px;
	background-position: 0 -500px;
	}
.note-pen-blue-large {
	width: 22px;
	height: 20px;
	background-position: -180px -530px;
	}
.note-pen-blue {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-top: -2px;
	background-position: -120px -345px;
	}
.note-pen-blue-small {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-top: 1px;
	background-position: -120px -290px;
	}	
.note-pen-green {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -120px -330px;
	}
.note-pen-green-large {
	width: 24px;
	min-width: 24px;
	height: 24px;
	background-position: -120px -305px;
	}
.note-pen-grey-large {
	width: 20px;
	height: 20px;
	background-position: -230px -1260px;
	}
.note-pen-orange-small {
	width: 14px;
	min-width: 14px;
	height: 14px;
	margin-top: 1px;
	background-position: -130px -175px;
	}
.note-greyed {
	width: 22px;
	margin-top: 2px;
	background-position: -30px -500px;
	}
.note-greyed-v2 {
	width: 26px;
	height: 25px;
	background-position: -150px -1682px;
	}
.notification {
	margin-left: 20px;
	background-position: -270px -307px;
	}
.ok {
	color: #fff;
	text-align: center;
	background-position: -40px -50px;
	}
.pad-small {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -60px -710px; 
	}
.pad-demo-small {
	width: 32px;
	height: 22px;
	background-position: -100px -710px; 
	}
.paper-arrow-right-blue-xs {
	width: 16px;
	min-width: 16px;
	height: 17px;
	background-position: -70px -1920px;
	}		
.paper-white-medium {
	width: 17px;
	height: 22px;
	background-position: -250px -950px; 
	vertical-align: middle;
	margin: -2px 10px 0 5px;
	}
.pen {
	width: 19px;
	min-width: 19px;
	background-position: -120px -360px;
	}
.pen-blue-medium {
	width: 14px;
	min-width: 14px;
	height: 16px;
	background-position: -100px -1880px;
	}
.pen-grey-small {
	width: 12px;
	min-width: 12px;
	height: 15px;
	background-position: -120px -380px;
	}
.pen-grey-medium {
	width: 14px;
	min-width: 14px;
	height: 18px;
	background-position: -100px -1860px;
	}
.pen-white-small {
	width: 10px;
	min-width: 10px;
	height: 14px;
	background-position: -130px -190px;
	}
.pen-white-large {
	width: 20px;
	height: 20px;
	background-position: -290px -1160px;
	}
.pen-white-large-thick {
	width: 20px;
	height: 20px;
	background-position: -230px -1240px;
	}
.performance-metrics-large {
	width: 24px;
	height: 24px;
	background-position: -230px -1320px;
	}
.active .performance-metrics-large,
a:hover .performance-metrics-large {
	background-position: -230px -1360px;
	}
.person-blue-small {
	width: 13px;
	min-width: 13px;
	height: 14px;
	background-position: -30px -1840px;
	}
.person-blue-large {
	width: 26px;
	height: 28px;
	background-position: -30px -1775px;
	}
.person-blue-light-large {
	width: 20px;
	min-width: 20px;
	height: 22px;
	background-position: -255px -1865px;
	}
.person-blue-outline-small {
	width: 13px;
	min-width: 13px;
	height: 16px;
	background-position: -170px -1735px;
	}
.phone-book-orange-large {
	width: 23px;
	height: 26px;
	background-position: -245px -1710px;
	}
.phone-book-orange-small {
	width: 16px;
	min-width: 16px;
	height: 18px;
	background-position: -270px -1735px;
	}
.phone-grey {
	width: 18px;
	min-width: 18px;
	height: 23px;
	background-position: -270px -600px;
	}
.phone-blue-xs {
	width: 11px;
	min-width: 11px;
	height: 18px;
	background-position: 0 -1920px;
	}
.play-blue {
	background-position: -30px -360px;
	}
.pill-green-large {
	width: 28px;
	height: 28px;
	background-position: 0 -1775px;
	}
.pill-green-medium {
	width: 20px;
	height: 20px;
	background-position: 0 -1810px;
	}
.pill-green-small {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: 0 -1840px;
	}	
.pill-blue-large {
	width: 28px;
	height: 28px;
	background-position: -30px -1775px;
	}
.pill-blue-medium {
	width: 22px;
	height: 22px;
	background-position: -30px -1810px;
	}
.pill-black-large {
	width: 28px;
	height: 28px;
	background-position: -120px -1775px;
	}
.pill-black-medium {
	width: 20px;
	height: 20px;
	background-position: -120px -1810px;
	}
.pill-black-small {
	width: 16px;
	height: 16px;
	background-position: -120px -1840px;
	}	
.plus-blue-medium {
	width: 22px;
	min-width: 22px;
	height: 22px;
	background-position: -315px -1200px;
	}
.plus-round-blue-strong {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -130px -1300px;	
	}
.plus-green-round {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -240px -500px;
	}
.plus-green-round-large {
	width: 28px;
	height: 28px;
	background-position: -180px -750px;
	}
.plus-green-round-xlarge {
	width: 56px;
	height: 56px;
	background-position: -70px -1600px;
	}
.plus-pms-green-round-xlarge {
	width: 56px;
	height: 56px;
	background-position: -130px -1600px;
	}
.plus-green-boxes-small {
	width: 12px;
	min-width: 12px;
	height: 14px;
	background-position: -210px -1600px;
	}
.add-form.inactive .plus-green-boxes-small,
.plus-grey-boxes-small {
	width: 12px;
	min-width: 12px;
	height: 14px;
	background-position: -210px -1615px;
	}
.plus-green-round-medium {
	width: 19px;
	height: 19px;
	background-position: -180px -780px;
	}
.plus-grey-round-large {
	width: 24px;
	height: 24px;
	background-position: -240px -750px;
	}
.plus-grey-box-white {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -70px -1520px;
	}
.plus-white {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -90px -210px;
	}
.plus-white-round-large {
	width: 26px;
	height: 26px;
	display: inline-block;
	margin: -2px 8px 0 5px;
	vertical-align: middle;
	background-position: -210px -750px;
	}
.pre-query {
	width: 13px;
	min-width: 13px;
	height: 10px;
	background-position: -20px -480px;
	}
.pre-query-large {
	width: 30px;
	height: 23px;
	background-position: 0 -520px;
	}
.print-blue-medium {
	width: 20px;
	height: 18px;
	background-position: -320px -990px;
	}
.private-note {
	width: 14px;
	min-width: 14px;
	height: 16px;
	background-position: 0px -480px;
	}
.quarantined-orange-large {
	width: 32px;
	height: 32px;
	background-position: -290px -1900px;
	}
.quarantined-orange-small {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -290px -1880px;
	}
.queries-metrics-large {
	width: 24px;
	height: 24px;
	background-position: -200px -1320px;
	}
.active .queries-metrics-large,
a:hover .queries-metrics-large {
	background-position: -200px -1360px;
	}
.questionmark-bubble-green-medium {
	width: 30px;
	height: 31px;
	background-position: 0 -1280px;
	}	
.questionmark-bubble-green-large {
	width: 44px;
	height: 46px;
	background-position: 0 -1230px;
	}
.questionmark-grey-medium-exed {
	width: 24px;
	height: 22px;
	background-position: -20px -1520px;
	}
.questionmark-bubble-grey-medium {
	width: 30px;
	height: 31px;
	background-position: -50px -1280px;
	}
.questionmark-bubble-plus-grey-large {
	width: 52px;
	height: 46px;
	background-position: -50px -1230px;
	}	
.questionmark-orange-small {
	width: 10px;
	min-width: 10px;
	height: 15px;
	background-position: -260px -470px;
	}
.questionmark-orange-xs {
	width: 8px;
	min-width: 8px;
	height: 12px;
	background-position: -260px -490px;
	}
.questionmark-orange-large {
	width: 18px;
	min-width: 18px;
	height: 28px;
	background-position: -270px -470px;
	}
.questionmark-red-small {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -110px -1450px;
	}
.questionmark-red-large {
	width: 14px;
	min-width: 14px;
	height: 22px;
	background-position: -160px -830px;
	}
.questionmark-white {
	width: 28px;
	height: 28px;
	background-position: -150px -600px;
	}
.questionmark-bubble-white {
	width: 26px;
	height: 27px;
	background-position: -240px -625px;
	}
.reference-data {
	width: 22px;
	height: 22px;
	background-position: -60px -625px;
	}
.reference-data-transfer-grey {
	width: 24px;
	height: 18px;
	background-position: -130px -1490px;
	}
.remove-black-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -315px -1160px;
	}
.remove-white-small {
	width: 12px;
	min-width: 12px;
	height: 12px;
	background-position: -160px -875px;
	}
.remove-red-small {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -120px -30px;
	}	
.resend-email-red-large {
	width: 24px;
	height: 24px;
	background-position: -225px -1600px;
	}
.reset-grey-medium {
	width: 24px;
	height: 17px;
	background-position: -290px -990px;
	}
.reset-red-medium {
	width: 20px;
	height: 20px;
	background-position: -240px -360px;
	}	
.review-grey-large {
	width: 40px;
	height: 52px;
	background-position: -240px -1050px;	
	}
.revise-grey-large {
	width: 47px;
	height: 53px;
	background-position: -180px -1050px;	
	}
.scheduled-reports {
	width: 26px;
	height: 34px;
	background-position: 0px -600px;
	}
.sdv {
	width: 16px;
	min-width: 16px;
	background-position: -40px -145px;
	}
.sdv-off {
	width: 16px;
	min-width: 16px;
	background-position: -40px -165px;
	}
.send-grey-medium {
	width: 23px;
	height: 20px;
	background-position: -290px -1010px;
	}
.seal-white {
	width: 15px;
	min-width: 15px;
	height: 26px;
	background-position: -290px -830px;
	}
.search-beige {
	width: 16px;
	min-width: 16px;
	height: 14px;
	background-position: -90px -910px;
	}
.search-large {
	width: 24px;
	height: 24px;
	background-position: -60px -500px;
	}
.setting {
	margin-left: 20px;
	background-position: -270px -387px;
	}
.setting-white {
	width: 20px;
	height: 20px;
	margin-top: -2px;
	vertical-align: middle;
	background-position: -360px -530px;
	}
.signed {
	background-position: -80px -50px;
	}
.signed-blue-outline {
	background-position: -100px -175px;
	}
.signed-grey-outline {
	background-position: -100px -150px;
	}
.signed-large {
	width: 50px;
	height: 50px;
	background-position: -90px -90px;
	}
.site-settings {
	width: 28px;
	height: 28px;
	background-position: -340px -1810px;
	}
.site-settings.active {
	background-position: -340px -1870px;
	}		
.smiley-green {
	width: 24px;
	height: 24px;
	background-position: 0 -890px;
	}	
.speedometer {
	width: 30px;
	height: 30px !important;
	background-position: -180px -250px;
	}
.sort-gray {
	width: 11px;
	min-width: 11px;
	height: 8px;
	background-position: -120px -790px;
	}
.sort-white {
	width: 11px;
	min-width: 11px;
	height: 8px;
	background-position: -120px -780px;
	}
.star-grey-hollow-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -80px -1860px;
	}
.star-blue-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -80px -1880px;
	}
.star-orange-medium {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -80px -1900px;
	}
.storage-indicator-low-red {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -190px -1740px;
	}
.storage-indicator-medium-orange {
	width: 10px;
	min-width: 10px;
	height: 16px;
	background-position: -200px -1740px;
	}
.storage-small {
	width: 18px;
	min-width: 18px;
	height: 20px;
	background-position: -240px -320px;
	}
.storage-xs {
	width: 9px;
	min-width: 9px;
	height: 10px;
	background-position: -240px -345px;
	}
.sun-orange {
	width: 8px;
	min-width: 8px;
	height: 8px;
	background-position: -245px -1700px;
	}
.sun-black {
	width: 12px;
	min-width: 12px;
	height: 12px;
	background-position: -290px -860px;
	}
.task {
	font-size: 12px !important;
	line-height: 20px;
	padding: 1px 2px 0 2px;
	color: #fff;
	text-align: center;
	background-color: #feb026;
	background-image: none !important;
	font-weight: 700;
	}
.tick {
	width: 20px;
	height: 17px;
	background-position: -330px -530px;
	}
.tick-blue-art-small {
	width: 16px;
	min-width: 16px;
	height: 14px;
	background-position: -270px -870px;
	}
.tick-green {
	width: 13px;
	min-width: 13px;
	height: 11px;
	margin-right: 3px;
	background-position: -360px -500px;
	}
.tick-green-small {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -30px -1190px;
	}
.tick-green-art-large {
	width: 23px;
	height: 20px;
	background-position: -180px -830px;
	}
.tick-green-art-small {
	width: 15px;
	min-width: 15px;
	height: 13px;
	background-position: -180px -855px;
	}
.tick-grey-light-small {
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -30px -1170px;
	}
.tick-grey-small {
	width: 21px;
	min-width: 21px;
	height: 21px;
	background-position: -90px -530px;
	}
.tick-grey-medium {
	width: 29px;
	height: 29px;
	background-position: -90px -560px;
	}
.tick-grey-transparent-outline-large {
	width: 38px;
	height: 38px;
	background-position: -350px -1700px;
	}
.tick-grey-art-large {
	width: 23px;
	height: 20px;
	background-position: -210px -830px;
	}
.tick-grey-art-small {
	width: 15px;
	min-width: 15px;
	height: 13px;
	background-position: -210px -855px;
	}
.tick-grey-darker-art-small {
	width: 16px;
	min-width: 16px;
	height: 14px;
	background-position: -270px -855px;
	}
.tick-white-art-small {
	width: 15px;
	min-width: 15px;
	height: 13px;
	background-position: -210px -870px;
	}
.tick-green-round-large {
	width: 44px;
	height: 44px;
	background-position: 0 -1110px;
	}
.tick-green-round-medium {
	width: 23px;
	height: 22px;
	background-position: 0 -1160px;
	}
.tick-green-round-small {
	width: 20px;
	height: 20px;
	background-position: 0 -1190px;
	}
.tick-green-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: 0 -1215px;
	}
.tick-blue-round-large {
	width: 44px;
	height: 44px;
	background-position: -50px -1110px;
	}
.tick-blue-round-medium {
	width: 22px;
	height: 22px;
	background-position: -50px -1160px;
	}
.tick-blue-round-small {
	width: 21px;
	height: 20px;
	background-position: -50px -1190px;
	}
.tick-blue-round-xs {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -50px -1215px;
	}
.tick-white-round-medium {
	width: 22px;
	height: 22px;
	background-position: -290px -1110px;
	}
.tick-white-blue-bg-round-large {
	width: 57px;
	height: 57px;
	background-position: -295px -1750px;
	}
.toggle-minus {
	width: 12px;
	min-width: 12px;
	height: 2px;
	background-position: -120px -210px;
	}	
.toggle-plus {
	width: 10px;
	min-width: 10px;
	height: 10px;
	background-position: -90px -210px;
	}
.tools-grey-large {
	width: 28px;
	height: 28px;
	background-position: -100px -800px;
	}
.tools-grey-round {
	width: 24px;
	height: 24px;
	background-position: -310px -1360px;
	}
.tools-white-medium {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: -2px 8px 0 5px;
	vertical-align: middle;
	background-position: -180px -800px;
	}
.tools-white {
	width: 16px;
	height: 16px;
	background-position: -100px -780px;
	}
.tools-yellow-large {
	width: 28px;
	height: 28px;
	background-position: -140px -800px;
	}
.tools-blue-round {
	width: 28px;
	height: 28px;
	background-position: -100px -750px;
	}
.tools-blue-round-small {
	width: 19px;
	height: 19px;
	background-position: -260px -800px;
	}
.transfer-grey {
	width: 31px;
	height: 24px;
	background-position: -320px -240px;
	}
.transfer-dark-grey-medium {
	width: 26px;
	height: 20px;
	background-position: -280px -710px;
	}
.transfer-grey-medium {
	width: 23px;
	height: 18px;
	background-position: -220px -710px;
	}
.transfer-orange {
	width: 31px;
	height: 24px;
	background-position: -320px -200px;
	}
.transfer-white {
	width: 31px;
	height: 24px;
	background-position: -320px -280px;
	}	
.transfer-white-medium {
	width: 23px;
	height: 18px;
	background-position: -250px -710px;
	}
.trash {
	width: 15px;
	min-width: 15px;
	background-position: -150px -360px;
	}
a.hover-icon:hover .trash {
	background-position: -360px -1400px;	
	}	
.trash-black-medium {
	width: 15px;
	min-width: 15px;
	height: 20px;
	background-position: -360px -1360px;
	}
.trash-black-tall-medium {
	width: 16px;
	min-width: 16px;
	height: 22px;
	background-position: -220px -1515px;
	}
.trash-black-tall-large {
	width: 32px;
	height: 44px;
	margin-top: -2px;
	background-position: -360px -1500px;
	}
.trash-black-tall-exclamation-medium {
	width: 22px;
	height: 22px;
	background-position: -320px -890px;
	}	
.trash-black-tall-exclamation-large {
	width: 43px;
	height: 44px;
	background-position: -315px -1500px;
	}
.trash-lid-off-black-tall-large {
	width: 32px;
	height: 44px;
	margin-top: -2px;
	background-position: -360px -1500px;
	}
.trash-grey-tall-medium {
	width: 16px;
	min-width: 16px;
	height: 22px;
	background-position: -290px -1490px;
	}
.trash-lid-off-grey-medium {
	width: 19px;
	min-width: 19px;
	height: 22px;
	margin-top: -2px;
	background-position: -290px -1515px;
	}
.trash-red {
	width: 15px;
	min-width: 15px;
	height: 21px;
	background-position: -210px -800px;
	}
.trash-grey-small {
	width: 13px;
	min-width: 13px;
	height: 17px;
	background-position: -150px -380px;
	}
.trash-grey-large {
	width: 17px;
	min-width: 17px;
	height: 22px;
	background-position: -230px -800px;
	}
.trash-white-medium {
	width: 16px;
	min-width: 16px;
	height: 22px;
	margin-top: -2px;
	background-position: -270px -1490px;
	}
.trash-lid-off-white-medium {
	width: 19px;
	min-width: 19px;
	height: 22px;
	margin-top: -2px;
	background-position: -270px -1515px;
	}	
.trash-large {
	width: 30px;
	height: 40px;
	background-position: -165px -380px;
	}
.unblind-grey-medium {
	width: 20px;
	height: 18px;
	background-position: -150px -1755px;
	}
.unblind-red-medium {
	width: 20px;
	height: 18px;
	background-position: -150px -1735px;
	}
.user {
	margin-left: 20px;
	background-position: -270px -348px;
	}
.users {
	width: 30px;
	height: 26px;
	background-position: -150px -90px;
	}
.user-certification-blue-small {
	width: 12px;
	min-width: 12px;
	height: 18px;
	background-position: -310px -1600px;
	}
.user-certificate {
	width: 72px;
	height: 100px;
	background-position: -75px -1660px;
	}
.user-certificate.inactive {
	background-position: 0 -1660px;
	}
.toggle {
	width: 30px;
	height: 15px;
	display: inline-block;
	background-position: -320px -485px;
	}
.toggle-on {
	width: 30px;
	height: 15px;
	display: inline-block;
	background-position: -270px -500px;
	}
.toggle-size {
	width: 14px;
	min-width: 14px;
	height: 14px;
	background-position: -300px -40px;
	}
.toggle-off {
	width: 30px;
	height: 15px;
	display: inline-block;
	background-position: -320px -500px;
	}
.unlocked-light-grey-large {
	width: 18px;
	min-width: 18px;
	height: 24px;
	background-position: -290px -960px;
	}
.unlocked-white-medium {
	width: 16px;
	min-width: 16px;
	height: 21px;
	margin-top: -4px;
	background-position: -270px -1180px;
	}
.unsigned {
	background-position: -360px 0;
	}
.update-blue {
	vertical-align: middle;
	width: 16px;
	min-width: 16px;
	height: 16px;
	background-position: -240px -380px;
	}
.viedoc-logo {
	width: 36px;
	height: 36px;
	background-position: -315px -40px;
	}	
.viedoc-logo-black {
	width: 100px;
	height: 32px;
	background-position: -150px -280px;
	}
.visit-date {
	width: 22px;
	margin-top: -2px;
	background-position: -120px -530px;
	}
h1 .icon {
	margin-right: 12px;
	}
h1 .patient {
	width: 30px;
	height: 27px;
	background-position: -190px -1865px;
	}
h1 .phone {
	width: 18px;
	height: 30px;
	background-position: -225px -1700px;
	}
.nav-header .icon {
	display: block;
	margin: 0 auto;
	overflow: hidden;
	background-image: url(../images/bg_sprite.png);
	background-repeat: no-repeat;
	background-size: 400px auto;
	background-color: transparent;
	} 
.nav-header .new-card {
	width: 30px;
	height: 25px;
	background-position: -270px -160px;
	} 
.nav-header .new-card-grey {
	width: 30px;
	height: 25px;
	background-position: -40px -1600px;
	} 	
.nav-header .transfer {
	width: 31px;
	height: 24px;
	background-position: -320px -160px;
	}
.nav-header .active .transfer {
	background-position: -320px -200px;
	} 
.nav-header .stamp {
	width: 30px;
	height: 25px;
	background-position: -270px -120px;
	}
.nav-header .grid {
	width: 30px;
	height: 25px;
	background-position: -270px -79px;
	}
.nav-header .studystatus {
	width: 30px;
	height: 30px;
	display: block !important;
	clear: both;
	float: none;
	background-position: -180px -250px;
	}
.nav-header .exclamation-red {
	width: 12px;
	height: 12px;
	margin: 0 0 0 21px;
	position: absolute;
	z-index: 2;
	left: 50%;
	background-position: -210px -250px;
	}
.nav-header .admin {
	width: 27px;
	height: 28px;
	display: block !important;
	clear: both;
	float: none;
	background-position: -150px -150px;
	}
.nav-header .designer {
	width: 26px;
	height: 28px;
	display: block !important;
	clear: both;
	float: none;
	background-position: -150px -200px;
	}
.nav-header .elearning {
	width: 26px;
	height: 28px;
	display: block !important;
	clear: both;
	float: none;
	background-position: -150px -250px;
	}	
.nav-header .phone {
	width: 16px;
	height: 26px;
	background-position: -320px -80px;
	}
.nav-header .phone.alert {
	width: 21px;
	height: 29px;
	background-position: -190px -1900px !important;
	}	
.nav-header .review {
	width: 20px;
	height: 26px;
	background-position: -320px -121px;
	}	
.nav-header ul.card-selector li .icon {
	margin: 0 !important;
	padding: 0 !important;
	background-image: url(../images/bg_sprite.png);
	background-repeat: no-repeat;
	background-size: 400px auto;
	text-indent: -9999px;
	}
.nav-header ul.card-selector li .prev {
	float: left;
	margin-top: 6px !important;
	min-width: 10px !important;
	height: 12px;
	display: block;
	background-position: -270px -240px;
	}
.nav-header ul.card-selector li .next {
	float: right;
	margin-top: 6px !important;
	min-width: 10px !important;
	height: 12px;
	display: block;
	background-position: -270px -280px;
	}
.nav-header ul.card-selector li.patient {
	float: left;
	color: #636363;
	height: 25px !important;
	min-width: 50px !important;
	display: block;
	padding-top: 18px !important;
	text-align: center;
	text-indent: 0;
	background-position: -251px -200px;
	font-size: 10px;
	overflow: visible;
	}


/* @group Grayscale Icons for Deleted Patients (Detail) */
.main-content.deleted .patient ul.progress-points li {
	background-color: #ebebeb;
	color: #969696;
	} 
.main-content.deleted .detail-area ul.tags li span.tag-default,
.main-content.deleted .detail-area ul.tags li span.tag-ready,
.main-content.deleted .detail-area ul.tags li span.tag-cancelled,
.main-content.deleted .detail-area ul.tags li span.tag-ongoing,

.main-content.deleted .icon.task {
	background-color: #969696;	
	}
.main-content.deleted .detail-area ul.tags li span.tag-default,
.main-content.deleted .detail-area ul.tags li span.tag-ready,
.main-content.deleted .detail-area ul.tags li span.tag-cancelled,	
.main-content.deleted .detail-area ul.tags li span.tag-ongoing	{
	border-color: #969696;
	}
.main-content.deleted .detail-area ul.tags li span.tag-default:before,
.main-content.deleted .detail-area ul.tags li span.tag-ready:before,
.main-content.deleted .detail-area ul.tags li span.tag-cancelled:before,
.main-content.deleted .detail-area ul.tags li span.tag-ongoing:before {
	background-position: -135px -1400px;
	border-right: #969696;
	}
.main-content.deleted .patient ul.progress-points li.half {
	background-color: #ebebeb;
	color: #969696;
	}
.main-content.deleted ul.date-list li.proposed {
	background-color: #ebebeb;
	}
.main-content.deleted ul.date-list li.planned,
.main-content.deleted ul.date-list + a.button.add-note {
	background-color: #e0e0e0;
	}
.main-content.deleted ul.date-list li.visit {
	background-color: #d6d6d6;
	}
.main-content.deleted .detail-area h2	{
	color: #969696;
	}	
.main-content.deleted .icon.attention,
.main-content.deleted .icon.error {
	background-position: 0 -1400px;
	}
.main-content.deleted .icon.ok {
	background-position: -40px -1400px;
	}
.main-content.deleted .icon.signed {
	background-position: -80px -1400px;
	}
.main-content.deleted .icon.add-plus-grey {
	background-position: -114px -1394px;
	}
.main-content.deleted .icon.add-plus-white {
	background-position: -154px -1394px;
	}
.main-content.deleted .icon.cra {
	background-position: 0px -1425px;
	}
.main-content.deleted .icon.dm {
	background-position: -20px -1425px;
	}
.main-content.deleted .icon.sdv {
	background-position: -40px -1425px;
	}
.main-content.deleted .icon.cra-off {
	background-position: 0px -1445px;
	}
.main-content.deleted .icon.dm-off {
	background-position: -20px -1445px;
	}
.main-content.deleted .icon.sdv-off {
	background-position: -40px -1445px;
	}
.main-content.deleted .slider ul li a .task:before {
	background-position: -20px -190px;
	}
.main-content.deleted .slider-list ul li .signed {
	background-position: -80px -1430px;
	}
.main-content.deleted .slider-list ul li .attention {
	background-position: -110px -1430px;
	}
.main-content.deleted .icon.clip {
	background-position: -135px -1430px;
	}
/* @end */

/* @group IE8 and IE9 Specific */	
.lt-ie9 .detail-area ul.tags li span.tag-default:before,
.lt-ie9 .detail-area ul.tags li span.tag-ready:before,
.lt-ie9 .detail-area ul.tags li span.tag-cancelled:before,
.lt-ie9 .detail-area ul.tags li span.tag-ongoing:before,
.lt-ie9 .admin section header h4 div.vote-bubble:before,
.lt-ie9 table.scrollable th.site .icon-search,
.lt-ie9 .pill dd .chosen-container-single .chosen-single b,
.lt-ie9 .patient ul.info li.gender,
.lt-ie9 .icon,
.lt-ie9 .nav-header .icon,
.lt-ie9 .nav-header ul.card-selector li .icon,
.lt-ie9 .demo-mode,
.lt-ie9 .demo-mode:active,
.lt-ie9 .demo-mode:hover,
.lt-ie9 .slider ul li a .task:before,
.lt-ie9 ul.nav-toggle-slider li a .view-default,
.lt-ie9 ul.nav-toggle-slider li a .view-list,
.lt-ie9 ul.nav-toggle-selection li a .view-grid,
.lt-ie9 ul.nav-toggle-selection li a .view-table,
.lt-ie9 .slider-wrapper .selector-left,
.lt-ie9 .slider-wrapper .selector-right,
.lt-ie9 .slider .shadow-left,
.lt-ie9 .slider .shadow-right,
.lt-ie9 .slider-wrapper .selector-up,
.lt-ie9 .slider-wrapper .selector-down,
.lt-ie9 .landing-page .content ul.list-messages li,
.lt-ie9 .landing-page .content ul.list-messages li.blue,
.lt-ie9 .landing-page .content ul.list-messages li.red,
.lt-ie9 ul.list-exports li a,
.lt-ie9 .landing-page .content ul.list-exports li a,
.lt-ie9 ul.list-templates li a,
.lt-ie9 .landing-page .content ul.list-templates li a,
.lt-ie9 ul.list-scheduled li a,
.lt-ie9 .landing-page .content ul.list-scheduled li a,
.lt-ie9 ul.list-notes li.green,
.lt-ie9 .landing-page .content ul.accordion > li > div.title .holder b.toggle,
.lt-ie9 .list-menu:before,
.lt-ie9 .admin h1 ul li.caret-right:before,
.lt-ie9 .designer footer ul.navigation li.title,
.lt-ie9 .search-result ul li a:before,
.lt-ie9 .search-result ul li a.active:before,
.lt-ie9 .sidebar-controls .levels .radioArea,
.lt-ie9 .sidebar-controls .levels .radioAreaChecked  {
	background-image: url('../images/bg_sprite_x1.png');
	}
/* @end */