.loading-large {
    width: 84px;
    height: 26px;
    margin: auto;
    background: url(../images/loading-boxes-ani_L.gif) 0 0;
  }

  .loading-medium {
    width: 58px;
    height: 18px;
    margin: auto;
    background: url(../images/loading-boxes-ani_M.gif) 0 0;
  }

  .loading-small {
    width: 42px;
    height: 13px;
    background: url(../images/loading-boxes-ani_S.gif) 0 0;
  }

  .horizontallyCenter {
    margin-top: 200px;
  }