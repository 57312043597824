.input-append,
.input-prepend {
  	display: inline-block;
  	margin-bottom: 10px;
  	font-size: 0;
  	white-space: nowrap;
 	vertical-align: middle;
	}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  	position: relative;
  	vertical-align: top;
    background-color: white;
	border: 1px solid #dbdbdb;
	border-left-color: #e0e0e0;
	border-right-color: #e0e0e0;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;
	-moz-box-shadow:    inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
     box-shadow:        inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
	display: block;
	font-size: 14px;
	margin: 0;
	padding: 6px;
	height: 32px;        
	}
.input-append input:focus,
.input-prepend input:focus,
.input-append select:focus,
.input-prepend select:focus,
.input-append .uneditable-input:focus,
.input-prepend .uneditable-input:focus {
  	z-index: 2;
	}

.input-append .add-on,
.input-prepend .add-on {
  	display: inline-block;
  	width: auto;
  	height: 20px;
  	min-width: 16px;
  	padding: 4px 5px;
  	font-size: 14px;
  	font-weight: normal;
  	line-height: 20px;
  	text-align: center;
  	text-shadow: 0 1px 0 #ffffff;
  	background-color: #eeeeee;
  	border: 1px solid #ccc;
	}

.input-append .add-on,
.input-prepend .add-on,
.input-append .btn,
.input-prepend .btn,
.input-append .btn-group > .dropdown-toggle,
.input-prepend .btn-group > .dropdown-toggle {
  	vertical-align: top;
  	-webkit-border-radius: 0;
  	-moz-border-radius: 0;
  	border-radius: 0;
	}

.input-append .active,
.input-prepend .active {
  	background-color: #a9dba9;
  	border-color: #46a546;
	}

.input-prepend .add-on,
.input-prepend .btn {
  	margin-right: -1px;
	}

.input-prepend .add-on:first-child,
.input-prepend .btn:first-child {
  	-webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
	}

.input-append input,
.input-append select,
.input-append .uneditable-input {
  	-webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
	}

.input-append input + .btn-group .btn:last-child,
.input-append select + .btn-group .btn:last-child,
.input-append .uneditable-input + .btn-group .btn:last-child {
  	-webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
	}

.input-append .btn {
  	float: left !important;
	}

.input-append .add-on,
.input-append .btn,
.input-append .btn-group {
  	margin-left: -1px; 
	}
.input-append .add-on:last-child,
.input-append .btn:last-child,
.input-append .btn-group:last-child > .dropdown-toggle {
  	-webkit-border-radius: 0 4px 4px 0;
  	-moz-border-radius: 0 4px 4px 0;
  	border-radius: 0 4px 4px 0;
	}
.input-prepend.input-append input,
.input-prepend.input-append select,
.input-prepend.input-append .uneditable-input {
  	-webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
	}
.input-prepend.input-append input + .btn-group .btn,
.input-prepend.input-append select + .btn-group .btn,
.input-prepend.input-append .uneditable-input + .btn-group .btn {
  	-webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
	}
.input-prepend.input-append .add-on:first-child,
.input-prepend.input-append .btn:first-child {
  	margin-right: -1px;
  	-webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
	}
.input-prepend.input-append .add-on:last-child,
.input-prepend.input-append .btn:last-child {
  	margin-left: -1px;
  	-webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
	}
.input-prepend.input-append .btn-group:first-child {
  	margin-left: 0;
	}
input.search-query {
  	padding-right: 14px;
  	padding-right: 4px \9;
 	padding-left: 14px;
  	padding-left: 4px \9;
  	/* IE7-8 doesn't have border-radius, so don't indent the padding */
  	margin-bottom: 0;
  	-webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
	}
.btn-group {
  	*display: inline;
  	*margin-left: .3em;
  	font-size: 0;
 	white-space: nowrap;
  	vertical-align: middle;
  	*zoom: 1;
  	float: left !important;
	}
.btn-group:first-child {
  	*margin-left: 0;
	}
.btn-group + .btn-group {
  	margin-left: 5px;
	}
.btn-group > .btn:first-child {
  margin-left: 0;
  -webkit-border-bottom-left-radius: 4px;
          border-bottom-left-radius: 4px;
  -webkit-border-top-left-radius: 4px;
          border-top-left-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-topleft: 4px;
}

.btn-group > .btn:last-child,
.btn-group > .dropdown-toggle {
  -webkit-border-top-right-radius: 4px;
          border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
          border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
}

.btn-group > .btn.large:first-child {
  margin-left: 0;
  -webkit-border-bottom-left-radius: 6px;
          border-bottom-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
          border-top-left-radius: 6px;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topleft: 6px;
}

.btn-group > .btn.large:last-child,
.btn-group > .large.dropdown-toggle {
  -webkit-border-top-right-radius: 6px;
          border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
          border-bottom-right-radius: 6px;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
}

.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active {
  z-index: 2;
	}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
	}

.btn-group > .btn + .dropdown-toggle {
  *padding-top: 5px;
  padding-right: 8px;
  *padding-bottom: 5px;
  padding-left: 8px;
  -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.125), inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
	}

.box-section .form-item.right .field-row.datetime-group,
.box-section .form-item.left .field-row.datetime-group {
	width: auto;
}
.field-row.items .input-append, .field-row.items .input-prepend {
	margin-bottom: 0;
}


.datetime-group .select div {
	width: 28px !important;
	height: 32px !important;
	padding: 0 !important;
	background: #fff !important;
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius:  4px 0 0 4px;
	-ms-border-radius:  4px 0 0 4px;
	-o-border-radius:  4px 0 0 4px;
	border-radius:  4px 0 0 4px;
	border-right: 0;
	-moz-box-shadow:    inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
     box-shadow:        inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
	}

.datetime-group .chosen-container {
	width: 28px !important;
	height: 32px !important;
	padding: 0 !important;
	-webkit-border-radius: 4px 0 0 4px;
	-moz-border-radius:  4px 0 0 4px;
	-ms-border-radius:  4px 0 0 4px;
	-o-border-radius:  4px 0 0 4px;
	border-radius:  4px 0 0 4px;
	border-right: 0;
	margin: 0;
	}
.datetime-group .chosen-container .chosen-drop {
	min-width: 180px;
	}
.datetime-group .chosen-container-single .chosen-single {
	padding: 0 30px 0 0;
	height: 32px;
	background: #fff !important;
	-moz-box-shadow:    inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
     box-shadow:        inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
                        
	}
.datetime-group .chosen-single div {
	top: 3px !important;
	right: 3px !important;
	}
.datetime-group .chosen-single div b {
	width: 32px !important;
	}
.datetime-group.hasReadOnly {
	border: none;
	background-color: #fff !important;
	}
.datetime-group.hasReadOnly .chosen-container-single .chosen-single,
.datetime-group.hasReadOnly #datefield,
.datetime-group.hasReadOnly .button,
.datetime-group.hasReadOnly .button:last-child {
	background-color: #f2f0ed !important; 
	border-color: #d0cecc;
	color: #646464;
	cursor: not-allowed;
	}
.datetime-group.hasReadOnly .select div, 
.datetime-group.hasReadOnly .chosen-container-single .chosen-single {
	border-color: #d0cecc !important;
	border-width: 2px;
	}
.datetime-group.hasReadOnly .button:last-of-type {
	border-right: solid 2px #d0cecc !important;
	}
.datetime-group.hasError,
.datetime-group.hasAttention {
	border: none;
	}
.datetime-group.hasError .select div,
.datetime-group.hasError .chosen-container-single .chosen-single {
	border-color: #ff4632 !important;
	border-width: 2px !important;
	}
.datetime-group.hasError #datefield {
	border-color: #ff4632;
	border-width: 2px;
	border-left: solid 1px #e0e0e0;
	border-right: solid 1px #e0e0e0;
	}
.datetime-group.hasError .button {
	border-color: #ff4632 !important;
	border-width: 2px;
	border-left: none;
	border-right: solid 1px #e0e0e0 !important;
	}
.datetime-group.hasError .button:last-child {
	border-left: none !important;
	border-right: solid 2px #ff4632 !important;
	}
.datetime-group.hasError .button:last-of-type {
	border-right: solid 2px #ff4632 !important;
	}	
.datetime-group.hasAttention .select div,
.datetime-group.hasAttention .chosen-container-single .chosen-single {
	border-color: #ff9900;
	border-width: 2px;
	}
.datetime-group.hasAttention #datefield {
	border-color: #ff9900;
	border-width: 2px;
	border-left: solid 1px #e0e0e0;
	border-right: solid 1px #e0e0e0;
	}
.datetime-group.hasAttention .button {
	border-color: #ff9900 !important;
	border-width: 2px;
	border-left: none;
	border-right: solid 1px #e0e0e0 !important;
	}
.datetime-group.hasAttention .button:last-child {
	border-left: none !important;
	border-right: solid 2px #ff9900 !important;
	}
.datetime-group.hasAttention .button:last-of-type {
	border-right: solid 2px #ff9900 !important;
	}
.datetime-group .select div span:before {
	top: 12px !important;
	}
.datetime-group .select ul {
	top: 30px;
	-moz-border-radius: 0px 4px 4px 4px;
	-ms-border-radius: 0px 4px 4px 4px;
	-o-border-radius: 0px 4px 4px 4px;
	-webkit-border-radius: 0px 4px 4px 4px;
	border-radius: 0px 4px 4px 4px;
	}	
.datetime-group .field-item {
	margin-left: 0 !important;
	}
.datetime-group #datefield {
	float: left;
	font-weight: normal;
	font-weight: 500 !important;
	font-style: normal;
	color: #323232;
	}
.datetime-group .button,
.datetime-group .button.disabled {
	opacity: 1;
	float: left;
	width: 34px !important;
	height: 32px !important;
	padding: 8px 0 0 0;
	text-align: center;
	background: #fff !important;
	border: solid 1px #dbdbdb;
	border-left: none;
	border-right-color: #e0e0e0;
	-moz-box-shadow:    inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
     box-shadow:        inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
	}
.datetime-group .button:last-of-type {
	-webkit-border-radius: 0 4px 4px 0;
	-moz-border-radius: 0 4px 4px 0;
	-ms-border-radius: 0 4px 4px 0;
	-o-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
	border-right-color: #dbdbdb;
	-moz-box-shadow:    inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
     box-shadow:        inset  0  2px 2px -2px rgba(0, 0, 0, 0.2), 
                        inset  0 -2px 2px -2px rgba(0, 0, 0, 0.2);
	}


.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 3px 10px 3px 10px !important;
  margin: 2px 0 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  -webkit-border-radius: 6px;
     -moz-border-radius: 6px;
          border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  color: #ffffff;
  text-decoration: none;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  outline: 0;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #999999;
}

.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  cursor: default;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open {
  *z-index: 1000;
}

.open > .dropdown-menu {
  display: block;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: not-allowed;
}

.btn {
  display: inline-block;
  *display: inline;
  padding: 4px 12px;
  margin-bottom: 0;
  *margin-left: .3em;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  *background-color: #e6e6e6;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  *border: 0;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  *zoom: 1;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
     -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled] {
  color: #333333;
  background-color: #e6e6e6;
  *background-color: #d9d9d9;
}


/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.datepicker {
  	padding: 4px;
  	margin-top: 1px;
  	-webkit-border-radius: 4px;
  	-moz-border-radius: 4px;
  	border-radius: 4px;
  	direction: ltr;
  	}
.datepicker .dow {
	padding-top: 10px !important;
	padding-bottom: 0 !important;
	height: auto !important;
	font-size: 12px;
	color: #646464;
	}
.datepicker-inline {
  	width: 220px;
	}
.datepicker.datepicker-rtl {
  	direction: rtl;
	}
.datepicker.datepicker-rtl table tr td span {
 	float: right;
	}
.datepicker-dropdown {
  	top: 0;
  	left: 0;
	}
.datepicker-dropdown:before {
  	content: '';
 	display: inline-block;
  	border-left: 7px solid transparent;
  	border-right: 7px solid transparent;
  	border-bottom: 7px solid #ccc;
  	border-bottom-color: rgba(0, 0, 0, 0.2);
  	position: absolute;
  	top: -7px;
  	left: 6px;	
  	}
.datepicker-dropdown:after {
  	content: '';
  	display: inline-block;
  	border-left: 6px solid transparent;
  	border-right: 6px solid transparent;
  	border-bottom: 6px solid #ffffff;
  	position: absolute;
  	top: -6px;
  	left: 7px;
	}
.datepicker-dropdown.right:after {
  	top: -6px;
  	left: auto;
  	right: 7px;
	}
.datepicker-dropdown.right:before {
  	top: -7px;
  	left: auto;
  	right: 6px;	
  	}	
	
.datepicker > div {
 	display: none;
	}
.datepicker.days div.datepicker-days {
  	display: block;
	}
div.datepicker-days {
  	width: 275px;
  	}
.datepicker.months div.datepicker-months {
  	display: block;
	}
div.datepicker-months {
  	width: 182px;
	}
div.datepicker-years {
  	width: 200px;
	}
div.datepicker-months table .switch {
  	width: auto;
  	min-width: 142px;
	}
div.datepicker-years table .switch {
  	width: auto;
  	min-width: 152px;
	}
div.datepicker-years table {
  	 width: 200px;
  	table-layout: fixed;
	}
div.datepicker-months table {
  	border-collapse: collapse;
  	border-spacing: 0;
  	 width: 182px;
  	table-layout: fixed;
	}
div.datepicker-months table tbody td,
div.datepicker-years table tbody td {
  	/*min-width: 130px;*/
  	padding-bottom: 6px;
  	padding-right: 2px;
	}
.datepicker.years div.datepicker-years {
  	display: block;
	}
.datepicker table {
  	margin: 0;
  	border-spacing: 2px;
  	border-collapse: separate;
	}
.datepicker td,
.datepicker th {
  	text-align: center;
  	width: 20px;
  	height: 20px;
  	-webkit-border-radius: 4px;
  	-moz-border-radius: 4px;
  	border-radius: 4px;
  	border: none;
	}
.datepicker td {
  	width: 37px;
 	height: 28px;
  	padding: 0;
  	background: none;
	}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  	background-color: transparent;
	}
.datepicker table tr td.day:hover {
  	background: #eeeeee;
  	cursor: pointer;
	}
.datepicker table tr td.old,
.datepicker table tr td.new {
  	color: #c8c8c8;
  	background: none !important;
	}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  	background: none;
  	color: #999999;
  	cursor: default;
	}
.datepicker table tr td.protocol-date {
  background: #fff;
  color: #323232;
  border: dashed 1px #3fa9f5;
}
.datepicker table tr td.protocol-date-prevnextmonth {
  background: #fff;
  color: #c8c8c8;
  border: dashed 1px #3fa9f5;
}
.datepicker table tr td.protocol-date-prevnextmonth-today {
  background: #e1eefa;
  color: #c8c8c8;
  border: dashed 1px #3fa9f5;
}
.datepicker table tr td.protocol-date-today {
  background: #e1eefb;
  color: #323232;
  border: dashed 1px #3fa9f5;
}
.datepicker table tr td.prevnextmonth-today {
  background: #e1eefa;
  color: #c8c8c8;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #e1eefa;
  color: #323232;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #e1eefa;
}
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #3fa9f5;
  color: #fff;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #3fa9f5;
  color: #fff;
}
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #3fa9f5;
  color: #fff;
  display: table-cell;
}
.datepicker table tr td span {
  	display: block;
  	width: 43px;
  	height: 28px;
  	padding: 5px 0 0 0;
  	margin: 0 0 2px 2px;
  	float: left;
  	cursor: pointer;
  	-webkit-border-radius: 4px;
  	-moz-border-radius: 4px;
  	border-radius: 4px;
  	text-align: center;
  	border: none;
	}
.datepicker-years table tr td span {
  	width: 46px;
	}
.datepicker table tr td span:hover {
  	background: #eeeeee;
	}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  	background: none;
  	color: #999999;
  	cursor: default;
	}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
   background-color: #3fa9f5;
  color: #fff;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #3fa9f5;
  	color: #fff;
	}
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  	background-color: #003399 \9;
	}
.datepicker table tr td span.old {
	color: #999999;
	}
.datepicker .table-condensed th {
 	padding: 4px 5px;
  	vertical-align: middle;
	}
.datepicker th.switch {
	width: 145px;
  	font-size: 16px;
  	color: #1e8ae4;
  	font-weight: 500;
  	font-style: normal;
  	line-height: 16px;
  	text-align: center;
  	padding: 14px 0 0 0 !important;
	}
.datepicker table thead {
	background-color: #fff !important;
	height: 20px !important;
	}
.datepicker table thead th {
	padding-top: 15px !important;
	}
.datepicker table th.prev,
.datepicker table th.next {
	width: 20px !important;
	height: 20px !important;
	line-height: 20px !important;
	padding: 0 0 0 11px;
	vertical-align: middle;
	text-align: left;
  	}
.datepicker table th.next {
	text-align: right;
	padding: 0 11px 0 0;
  	} 
.datepicker thead tr th.prev .icon-arrow-left {
	width: 8px;
	height: 13px;
	display: inline-block;
	background-image: url(../images/bg_sprite.png);
	background-repeat: no-repeat;
	background-size: 400px auto;
	background-position: -360px -470px;
	}
.datepicker thead tr th.next .icon-arrow-right {
	width: 8px;
	height: 13px;
	display: inline-block;
	background-image: url(../images/bg_sprite.png);
	background-repeat: no-repeat;
	background-size: 400px auto;
	background-position: -380px -470px;
	}
.datepicker thead tr:first-child th,
.datepicker tfoot tr:first-child th {
  	cursor: pointer;
	}
.datepicker tfoot tr:first-child th:hover {
  	background: #eeeeee;
	}
.datepicker .cw {
  	font-size: 10px;
  	width: 12px;
  	padding: 0 2px 0 5px;
  	vertical-align: middle;
	}
.datepicker thead tr:first-child th.cw {
  	cursor: default;
  	background-color: transparent;
	}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  	display: block;
  	cursor: pointer;
  	width: 16px;
  	height: 16px;
	}
/*!
 * Timepicker Component for Twitter Bootstrap
 *
 * Copyright 2013 Joris de Wit
 *
 * Contributors https://github.com/jdewit/timepicker/graphs/contributors
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
.datetimepicker {
  	position: relative;
	}
.timepicker.pull-right .timepicker-widget.dropdown-menu {
  	left: auto;
  	right: 0;
	}
.timepicker.pull-right .timepicker-widget.dropdown-menu:before {
  	left: auto;
  	right: 12px;
	}
.timepicker.pull-right .timepicker-widget.dropdown-menu:after {
  	left: auto;
  	right: 13px;
	}
.timepicker .add-on {
  	cursor: pointer;
	}
.timepicker .add-on i {
  	display: inline-block;
  	width: 16px;
  	height: 16px;
	}
.timepicker-widget.dropdown-menu {
	z-index: 8041 !important;
	padding: 10px 7px 10px 7px !important;
	min-width: 152px;
	}
.timepicker-widget.dropdown-menu.open {
  	display: inline-block;
	}
.timepicker-widget.dropdown-menu:before {
  	border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  	border-left: 7px solid transparent;
  	border-right: 7px solid transparent;
  	content: "";
  	display: inline-block;
  	left: 9px;
  	position: absolute;
  	top: -7px;
	}
.timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
  top: -6px;
	}
.meridian .dropdown-menu {
	min-width: 202px !important;
	}
.timepicker-widget a.btn,
.timepicker-widget input {
  border-radius: 4px;
	}
.timepicker-widget table {
  width: 100%;
  margin: 0;
	}
.timepicker-widget table td {
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #fff;
}
.timepicker-widget table td:not(.separator) {
  min-width: 30px;
}
.timepicker-widget table td.separator {
  font-size: 30px;
}
.timepicker-widget table td span {
  width: 100%;
}
.timepicker-widget table td a {
  border: 1px transparent solid;
  width: 100% !important;
  display: inline-block;
  margin: 0;
  padding: 0px 0 0 0;
  outline: 0;
  color: #333;
}
.timepicker-widget table td a:hover {
  
}
.timepicker-widget table td a i {
  margin-top: 2px;
}
.timepicker-widget table td input {
	font-size: 30px;
	border: 1px solid #fff;
	width: 38px;
	height: 34px;
	margin: 0;
	padding: 0;
	box-shadow: none;
	text-align: center;
	margin: 0 auto;
}
.timepicker-widget table td input.timepicker-meridian {
	width: 34px;
	height: 26px;
	color: #fff;
	font-size: 14px;
	background-color: #3fa9f5;
	border: 1px solid #3fa9f5;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	border-radius: 4px;	
	margin: 0;
	padding: 0;
	box-shadow: none;
	text-align: center;
	margin: 0 auto;
}
.timepicker-widget .modal-content {
  padding: 4px;
}

.timepicker-widget .icon-chevron-up {
	width: 13px;
	height: 8px;
	display: inline-block;
	background-image: url(../images/bg_sprite.png);
	background-repeat: no-repeat;
	background-size: 400px auto;
	background-position: -360px -485px;
	}
.timepicker-widget .icon-chevron-down {
	width: 13px;
	height: 8px;
	display: inline-block;
	background-image: url(../images/bg_sprite.png);
	background-repeat: no-repeat;
	background-size: 400px auto;
	background-position: -380px -485px;
	}
	

/* @group IE8 Specific */

.lt-ie9 .datepicker thead tr th.prev .icon-arrow-left,
.lt-ie9 .datepicker thead tr th.next .icon-arrow-right,
.lt-ie9 .timepicker-widget .icon-chevron-up,
.lt-ie9 .timepicker-widget .icon-chevron-down {
	background-image: url(../images/bg_sprite_x1.png);
	}

/* @end */	
	