.logistics-modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3) !important;
    z-index: 6;
}

.logistics-modal-open {
    overflow-x: hidden;
    overflow-y: auto;
    display: block;
}

.logistics-modal-closed {
    display: none;
}

.logistics-modal-main {
    position:relative;
    height: auto;
    margin: 1.75rem auto;
    margin-top: 0;
    max-width: 860px;
}