#formcanvas {
    visibility: visible;	
}
.style-switcher {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
}
.modal header.main {
    position: relative;
}
.modal .content-area {
    padding-top: 0;
}